export const linkResolver = doc => {
    if (doc.type === 'about') return `/about/`;
    if (doc.type === 'careers') return `/careers/`;
    if (doc.type === 'case_study') return `/study/${doc.uid}`;
    if (doc.type === 'contact') return `/contact/`;
    if (doc.type === 'culture') return `/culture/`;
    if (doc.type === 'history') return `/history/`;
    if (doc.type === 'insight') return `/insight/${doc.uid}`;
    if (doc.type === 'insights') return `/insights/`;
    if (doc.type === 'leadership') return `/leadership/`;
    if (doc.type === 'newsroom') return `/newsroom/`;
    if (doc.type === 'person') return `/bio/${doc.uid}`;
    if (doc.type === 'post') return `/post/${doc.uid}`;
    if (doc.type === 'simple_page') return `/page/${doc.uid}`;
    if (doc.type === 'specialty') return `/specialty/${doc.uid}`;
    if (doc.type === 'work') return `/work/`;

    return '/';
};
