module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"marino-pr","path":"/preview","previews":true,"pages":[{"type":"Person","match":"/bio/:uid","path":"bio-preview","component":"/opt/build/repo/src/templates/bio.jsx"},{"type":"Case_study","match":"/study/:uid","path":"case-study-preview","component":"/opt/build/repo/src/templates/case-study.jsx"},{"type":"Insight","match":"/insight/:uid","path":"insight-preview","component":"/opt/build/repo/src/templates/insight.jsx"},{"type":"Post","match":"/post/:uid","path":"post-preview","component":"/opt/build/repo/src/templates/post.jsx"},{"type":"Simple_page","match":"/page/:uid","path":"page-preview","component":"/opt/build/repo/src/templates/simple-page.jsx"},{"type":"Specialty","match":"/specialty/:uid","path":"specialty-preview","component":"/opt/build/repo/src/templates/specialty.jsx"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"marino-pr","short_name":"marino","start_url":"/","background_color":"#D32030","theme_color":"#D32030","display":"minimal-ui","icon":"src/images/icon_marino.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-42313530-2","head":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"525873011120108"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
