import "./src/fonts/fonts.css";
import React from "react";
import { ParallaxProvider } from "react-scroll-parallax";

const { registerLinkResolver } = require("gatsby-source-prismic-graphql");
const { linkResolver } = require("./src/utils/linkResolver");

registerLinkResolver(linkResolver);

export const wrapPageElement = ({ element, props }) => {
  return <ParallaxProvider>{element}</ParallaxProvider>
};
